<script>
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";

@Component
export default class AfterSignUpModal extends Vue {
  redirect() {
    this.$emit("close-modal");
    this.$router.push({
      path: "/login",
      query: { redirect: this.$route.query.redirect },
    });
  }
}
</script>
<template>
  <div class="after-signup">
    <div>{{ $t("signup.email-has-been-sent") }}</div>
    <button class="button" @click="redirect">
      {{ $t("signup.i-understand") }}
    </button>
  </div>
</template>
<style scoped lang="scss">
.after-signup {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    margin-top: 2rem;
  }
}
</style>
